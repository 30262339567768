<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;500;700&display=swap');
@import url('//at.alicdn.com/t/font_2777020_z1ejwgyqvic.css');

html, body {
  height: 100%;
  margin: 0;
  
}

body {
  background-color: #FAFAFA;
  font-family: 'Noto Sans SC', sans-serif;
}

.imageShow {
  width: 100%;
  height: auto;
}

.rb_PushWork {
  position: absolute;
  right: 30px;
  bottom: 30px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.qrcode_PushWork {
  width: 100px;
}
</style>
